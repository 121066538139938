import React, { useCallback, useState } from "react";
import { auth } from "../../firebase";
import { SyncLoader } from "react-spinners";
import Navbar from "../Navbar/Navbar";

// const user = firebase.auth().currentUser;
// const newPassword = getASecureRandomPassword();

// user.updatePassword(newPassword).then(() => {
//   // Update successful.
// }).catch((error) => {
//   // An error ocurred
//   // ...
// });

function UpdatePassword() {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleChange = (e, target) => {
    setFormData((prev) => {
      return {
        ...prev,
        [target]: e.target.value,
      };
    });
  };

  const checkForSimilarity = useCallback(() => {
    const { password, confirmPassword } = formData;
    const check = Boolean(password);
    return check && password !== confirmPassword;
  }, [formData]);

  const handleSubmit = (e) => {
    setLoader(true);
    setDisableSubmit(true);
    e.preventDefault();
    const { password } = formData;
    auth.currentUser
      .updatePassword(password)
      .then(() => {
        setDisableSubmit(false);
        setLoader(false);
        setFormData({
          password: "",
          confirmPassword: "",
        })
      })
      .catch((error) => {
        setDisableSubmit(false);
        console.error(error);
        alert("Some error occured, either both passwords doesn't match or password length is less than 6")
        setLoader(false)
      });
  };

  return (
    <>
      {/* <div>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            onChange={(e) => handleChange(e, "password")}
          />
          <input
            type="password"
            onChange={(e) => handleChange(e, "confirmPassword")}
          />
          <button
            disabled={checkForSimilarity() || disableSubmit}
            type="submit"
          >
            Change Password
          </button>
        </form>
      </div> */}

      <Navbar />

      <div className="mainContainer">
        <div className="loginheading Mobileview">KINEMATICS CLASSES</div>
        <div className="notification Mobileview">
          *Results are out. Login here check your score.
        </div>
        <div></div>
        <div className="container">
          <div className="loginContainer">
            <div className="imgContainer">
              <img
                className="Webview"
                src="./a-small-student-studying-in-night.jpg"
                alt=""
              ></img>
              <img className="Mobileview" src="./newMobile.jpg" alt=""></img>
            </div>
            <div className="formContainer">
              <div className="loginheading Webview">KINEMATICS CLASSES</div>
              <div className="notification Webview">
                *Make sure both entered passwords are same
              </div>
              <div className="subHeading">Change Password</div>
              <form onSubmit={handleSubmit} className="form">
                  <input
                    type="password"
                    onChange={(e) => handleChange(e, "password")}
                    placeholder="Enter Password"
                    required={true}
                    value={formData.password}
                  />
                  <input
                    type="password"
                    onChange={(e) => handleChange(e, "confirmPassword")}
                    placeholder="Confirm Password"
                    required={true}
                    value={formData.confirmPassword}
                  />
                  <button
                    className="loginButton"
                    disabled={checkForSimilarity() || disableSubmit}
                    type="submit"
                  >
                    {loader? <SyncLoader color="#F4F4FF" size={10} /> : 'Change Creds'}
                  </button>
              </form>
              <div className="bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePassword;
