import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import LandingPage from "../LandingPage/LandingPage";
import AutoOpenModal from "../AutoOpenModal/AutoOpenModal"
import './Home.css'

const Home = () => {

  const messages = ["Batch starting date : Class IX : 20 FEB Class X : 20 FEB Class XI: 20 March Class XII: 1 March Class XII pass IIT: 10th April Class XII pass NEET: 10th May", "Notification for KC-SAT (Kinematics classes-Scholastic Aptitude Test) Test date : 3rd March   "]

  return (
    <>
      <Navbar />
      <LandingPage />
      {/* <AutoOpenModal messages={messages}/> */}
      <a href="https://wa.me/8527915213" target="_blank">
        <img
          className="floating__hello"
          src="/helloFloating.png"
          alt=""
        />
      </a>
    </>
  );
};

export default Home;
