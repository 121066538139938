import React from "react";
import "./Institute_Explore.css";
import Youtube from './Youtube/Youtube.js'
import Contact from "./Contact/Contact.js";
import Results from "./PreviousResults/Results.js";
import Results2 from "./PreviousResults/Results2.js";
import { useNavigate } from "react-router-dom";
import Courses from "../Courses/Courses.js";

const Institute_Explore = () => {

  return (
    <div className="ins__container">
      <div className="ins_img_container">
        <img
          src="/KinematicsClassesText.png"
          alt=""
          className="ins__bg_kinematics_left"
        />
      </div>
      <div className="ins__img_container">
        <img
          src="/KinematicsClassesText.png"
          alt=""
          className="ins__bg_kinematics_right"
        />
      </div>
      <div className="ins__heading_container">
        <span className="ins__heading">Explore Institute</span>
        <img alt="" src="/dropdownarrow.png" />
      </div>
      <div className="ins__card_container">
        <div className="ins__card__upper">
          <img src="/institute3.jpg" alt="" className="left__grad" />
          <img src="/institute1.jpg" alt="" className="responsiveImg"/>
          <img src="/institute2.jpg" alt="" />
          <img src="/institute4.jpg" alt="" className="right__grad" />
        </div>
        <div className="ins__card__lower">
          <div className="ins__heading our__directors">&gt; &gt; &gt; Directors' Vision</div>
          {/* Directors */}
          <div className="ins__card__container__directors">
            <div className="upper__commas">“</div>
            <div className="director__card__responsive">
            <div className="director__card 1">
              <img src="/director_1.jpg" alt="" />
              <div className="director_name">
                Deepak Sharma
              </div>
              <div>Academic Director</div>
              <div style={{color: '#ED1D24', fontWeight: 500, marginTop:"3px"}}>B.Tech. (CSE)</div>
              <p>
              A dedicated educator with over two decades of experience, Mr. Sharma excels in adapting teaching methods for diverse students, particularly in mentoring JEE top rankers. His innovative approaches make physics and mathematics engaging and understanding in depth, guiding students to excel in exams like JEE and NEET, fostering a lasting passion for Physics and mathematics.
              </p>
            </div>
            <div className="director__card 2">
              <img src="/director_2.jpg" alt="" />
              
              <div className="director_name">
                Lokeshwary Sharma
              </div>
              <span>Academic Director</span>
              <span style={{color: '#ED1D24', fontWeight: 500, marginTop:"3px"}}>B.Tech. (ECE), M.Tech. (VLSI)</span>
              <p>
              With a Master’s in VLSI design and over 16 years in education, Her life has always been about her passion about Chemistry to make it interesting, logical and aligned and she is always keen to deliver the indepth subject knowledge to the ones seeking it. She has left no stone unturned to alleviate aspirants looking for proper guidance in the field of Chemistry. Her charm and effective communication create vibrant classes, fostering strong teacher-student bonds.
              </p>
            </div>
            <div className="director__card 3">
              <img src="/director_3.jpeg" alt="" />
              <div className="director_name">
                Mayank Sharma
              </div>
              <span>Managing Director</span>
              <span style={{color: '#ED1D24', fontWeight: 500, marginTop:"3px"}}>MBA (Marketting)</span>
              <p>
              Mayank Sir has vast experience to manage and motivate students to prepare for IIT JEE and NEET. He exercises his duties with reasonable care, skill and diligence. He is energetic and enthusiastic to take motivational sessions and also discusses Geopolitical situation with students to relax them, increasing General awareness. Knows teenage problems and have a wholesome solution to each of them.
              </p>
              </div>
            </div>
            <span className="lower__commas">”</span>
          </div>
          <p className="director__vision">"Our vision is to establish an institution which is not actually looking for quantity but for quality. Our foremost target is to get a single digit rank in IIT JEE Advanced, IIT JEE Main and NEET from the Ghaziabad itself. We are targetting to build a foundational and conceptual knowledge in a student so that the student can enjoy the subjects without taking much pressure. Our vision primarily includes a wholesome development of students that involves the intellectual, physical, social, moral, ethical, emotional aspects. At Kinematics Classes, we always encourage such events which enhance their physical and emotional abilities apart from academics."</p>

          {/* Previous Year Results */}
          <div className="ins__prev__results">
            <div
              className="ins__heading our__directors"
              style={{
                color: "#0F3D82",
              }}
            >
              &gt; &gt; &gt; Previous Top Achievers
            </div>
            <div className="prev__results "><Results /></div>
            <div className="prev__results_responsive"><Results2 /></div>
          </div>
        </div>
        <Courses/>
      </div>
    </div>
  );
};
export default Institute_Explore;