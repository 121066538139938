import React from "react";
import "./Youtube.css";

const Youtube = () => {
  return (
    <div className="youtube__container">
      <div className="youtube__iframe">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/9OdkPKcFfNI?si=rGX4m7ETwlDTuEs7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="youtube__text">
      At Kinematics classes we believe in utmost academic honesty starting a topic from zero level and taking it to the most difficult level gradually. Our faculty team is sincere, the best in the industry and has sharp question solving abilities which motivate students to do questions correctly and quicker. 

      Our ultimate motivation is *ज्ञानं परमं ध्येयम् (Knowledge is our supreme goal) and at KINEMATICS CLASSES, our faculties, our students and every bit associated with us will stick to that.
      </div>
    </div>
  );
};

export default Youtube;
