import React, { useState, useEffect } from "react";
import "./Results.css";
import { auth, db } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

const Results = () => {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [user, setUser] = useState();
  const [resultData, setResultData] = useState([]);

  const setMetaData = () => {
    auth.onAuthStateChanged((res) => {
      setUser(res);
      var mail = res.email;
      var dummy = mail.substring(0, mail.indexOf("@"));
      setName(dummy);
    });
  };

  useEffect(() => {
    const fetch = async () => {
      db.collection("resultsPDF_db").onSnapshot((snapshot) => {
        setResultData(
          snapshot.docs.map((data) => {
            return {
              date: data.data().testDate,
              name: data.data().testName,
              pdfUrl: data.data().pdfUrl,
            };
          })
        );
      });
    };

    fetch();
    console.log(resultData);
    setMetaData();
    return () => {};
  }, []);

  const logout = () => {
    auth.signOut();
    navigate("/login");
  };

  return (
    <>
      <Navbar />
      <div className="results__container">
        <div className="Adm_heading">
          <span>Welcome to Results Portal</span>
        </div>
        <div className="Adm_sub_heading">
          <span>Results Out Now !!!</span>
        </div>
        <div style={{ width: "80%" }}>
          <Link className="log__out__btn" to={"/update-password"}>
            Update Creds
          </Link>
        </div>
        <div className="results__inner__container">
          <span className="result__name__heading">
            <i>Hello</i>
            <span style={{ fontWeight: "600" }}> {name},</span>{" "}
            <i>
              now you can check your result by clicking on below given links.
            </i>
          </span>
          <div className="result__container__table">
            <table>
              <tr>
                <th style={{ textAlign: "center" }}>Test No.</th>
                <th style={{ textAlign: "center" }}>Date</th>
                <th style={{ padding: "0px 4%" }}>Paper</th>
                <th style={{ textAlign: "center" }}>View result</th>
              </tr>
              {resultData.map((data, i) => {
                return (
                  <tr key={i}>
                    <th
                      style={{
                        fontWeight: "400",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      {i + 1}
                    </th>
                    <th
                      style={{
                        fontWeight: "400",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {data.date}
                    </th>
                    <th
                      style={{
                        fontWeight: "400",
                        width: "50%",
                        padding: "0px 4%",
                      }}
                    >
                      {data.name}
                    </th>
                    <th
                      className="pdf__icon__th"
                      style={{
                        cursor: "pointer",
                        fontWeight: "400",
                        textDecoration: "underline",
                      }}
                    >
                      <img style={{ height: "22px" }} src="/pdf_icon.png" />
                      <a href={data.pdfUrl} target="_blank">
                        View
                      </a>
                    </th>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Results;
