import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyB9proFUl93sTsvg3O1faPGAtohjYLOjY0",
    authDomain: "kinematics-classes.firebaseapp.com",
    projectId: "kinematics-classes",
    storageBucket: "kinematics-classes.appspot.com",
    messagingSenderId: "993810036623",
    appId: "1:993810036623:web:c52eec53a3fd1c6d03fdfa",
    measurementId: "G-1XSRVN7Q50"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const analytics = getAnalytics(firebaseApp);

export { db, auth, analytics};