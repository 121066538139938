import React, { useState } from "react";
import "./Contact.css";
import { db } from "../../../../firebase";
import { Link } from "react-router-dom";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [course, setCourse] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [submit, setSubmit] = useState(false);

  function randomString() {
    var result = "";
    let length = 20;
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  const handleSubmit = (e) => {
    
    if (name === "" || email === "" || course === "" || mobile === "" || message === "") {
      alert("Kindly fill all the details.");
    } else {
      const id = randomString();
      e.currentTarget.disabled = true;

      db.collection("query_db").doc(id).set({
        name: name,
        email: email,
        course: course,
        mobile: mobile,
        message: message,
      });

      setName("");
      setEmail("");
      setCourse("");
      setMobile("");
      setMessage("");
      setSubmit(true);
    }
  };

  return (
    <>
      <div className="contact__container">
        <div className="contact__left">
          <p>
            For any update, you can follow our social media page or to contact
            us fill the given form. Our support team will assist you shortly.
          </p>
          <span>Follow Us on:</span>
          <div className="contact__socials">
            <a
              href="https://www.youtube.com/@KINEMATICSCLASSES24"
              target="_blank"
            >
              <img src="/youtube.png" alt="icon" />
            </a>
            <a
              href="/https://www.facebook.com/kinematicsclasses.page"
              target="_blank"
            >
              <img src="/facebook.png" alt="icon" />
            </a>
            <a
              href="https://www.instagram.com/kinematicsclassespvtltd/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/instagram.png" alt="icon" />
            </a>
            <a href="https://wa.me/8527915213" target="_blank">
              <img src="/whatsapp.png" alt="icon" />
            </a>
          </div>
        </div>
        <div className="contact__right">
          <span>&gt; &gt; &gt; Contact Us</span>
          <input
            type="text"
            placeholder="Full Name"
            required={true}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <select
            style={{opacity: 0.8}}
            name="course"
            onChange={(e) => setCourse(e.target.value)}
            value={course}
            required={true}
          >
            <option value="" style={{ display: "none" }}>
              Class/Course
            </option>
            <option value="9th">Class 9</option>
            <option value="10th">Class 10</option>
            <option value="11Jee">Class 11 (JEE)</option>
            <option value="11Neet">Class 11 (NEET)</option>
            <option value="12Jee">Class 12 (JEE)</option>
            <option value="12Neet">Class 12 (NEET)</option>
            <option value="12PassJee">Class 12 pass (JEE)</option>
            <option value="12PassNeet">Class 12 pass (NEET)</option>
          </select>
          <input
            type="number"
            placeholder="Contact Number"
            required={true}
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <textarea
            style={{ resize: "none", overflow: "hidden" }}
            placeholder="Message"
            name=""
            id=""
            cols="30"
            rows="4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button
            onClick={handleSubmit}
            className={submit ? "button__inactive" : "headingBottom"}
            style={{ fontFamily: "poppins" }}
          >
            {submit ? "Submitted" : "Submit"}
          </button>
        </div>
      </div>
      <div className="iframe__map">
        <span>
          <iframe
            id="map-canvas"
            className="map_part"
            width="500"
            height="375"
            frameborder="0"
            marginheight="0"
            marginwidth="10"
            src="https://maps.google.com/maps?width=100%&amp;height=100%&amp;hl=en&amp;q=AVS city square, Ghaziabad&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            Powered by{" "}
            <a href="https://www.googlemapsgenerator.com">
              embed google maps html
            </a>{" "}
            and <a href="cancelgamstop.com/"></a>
          </iframe>
        </span>
        <br />
        <div className="contact__details">
          <div className="contact__details__address">
            <div className="contact__details__heading">Contact Us</div>
            <div>
              <div>
                17, 1<sup>st</sup> floor, Avs City Square
              </div>
              <div>Raj Nagar Extension, Ghaziabad,</div>
              Uttar Pradesh 201017
            </div>
            &
            <div>
              <div>44, F Block,</div>
              <div>Raj Nagar Extension, Ghaziabad,</div>
              Uttar Pradesh 201017
            </div>
            <div>
              <div>Deepak Sir: 8527915213</div>
              <div>Lokeshwary Ma'am: 9953893662</div>
            </div>
            <div>kinematicsclasses24@gmail.com</div>
          </div>
          <div className="contact__details__links">
            <div className="contact__details__heading">Quick Links</div>
            <a
              href="/"
              style={{
                textDecoration: "none",
                color: "#ffff",
                cursor: "pointer",
              }}
            >
              Result
            </a>
            <a
              href="/admission"
              style={{
                textDecoration: "none",
                color: "#ffff",
                cursor: "pointer",
              }}
            >
              Admission
            </a>
            <a
              href="#contact"
              style={{
                textDecoration: "none",
                color: "#ffff",
                cursor: "pointer",
              }}
            >
              Contact
            </a>
            <a
              href="/login"
              style={{
                textDecoration: "none",
                color: "#ffff",
                cursor: "pointer",
              }}
            >
              Student Login
            </a>
            <a
              href="#courses"
              style={{
                textDecoration: "none",
                color: "#ffff",
                cursor: "pointer",
              }}
            >
              Courses
            </a>
            <Link
              to="/privacy-policy"
              style={{
                textDecoration: "none",
                color: "#ffff",
                cursor: "pointer",
              }}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className="contact__details__bottom__leftcontainer"></div>
      </div>
      <div className="copyright__div">
        Copyright 2024 &copy; All Rights Reserved by Kinematics Classes
      </div>
    </>
  );
};

export default Contact;
