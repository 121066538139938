import React from 'react'

const Batch_starting_date = ({
  releasedDate,
  heading,
  description,
  date9,
  date10,
  date11Jee,
  date11Neet,
  date12Jee,
  date12Neet,
  date13Jee,
  date13Neet
}) => {
  return (
    <div className='notifications'>
        <div className='notification__'>
            <div className='upload_date'>{releasedDate}</div>
            <hr style={{width:"100%"}}></hr>
            <div className='notification__heading'>{heading}</div>
            <div style={{display: date9 === undefined ? "none" : "block"}}><span style={{fontWeight:"500"}}>Class IX : </span>{date9}</div>
            <div style={{display: date10 === undefined ? "none" : "block"}}><span style={{fontWeight:"500"}}>Class X : </span>{date10}</div>
            <div style={{display: date11Jee === undefined ? "none" : "block"}}><span style={{fontWeight:"500"}}>Class XI JEE : </span>{date11Jee}</div>
            <div style={{display: date11Neet === undefined ? "none" : "block"}}><span style={{fontWeight:"500"}}>Class XI NEET : </span>{date11Neet}</div>
            <div style={{display: date12Jee === undefined ? "none" : "block"}}><span style={{fontWeight:"500"}}>Class XII JEE : </span>{date12Jee}</div>
            <div style={{display: date12Neet === undefined ? "none" : "block"}}><span style={{fontWeight:"500"}}>Class XII NEET : </span>{date12Neet}</div>
            <div style={{display: date13Jee === undefined ? "none" : "block"}}><span style={{fontWeight:"500"}}>Class XII PASS JEE : </span>{date13Jee}</div>
            <div style={{display: date13Neet === undefined ? "none" : "block"}}><span style={{fontWeight:"500"}}>Class XII PASS NEET : </span> {date13Neet}</div>
            <div>{description}</div>
        </div>
        <hr style={{width:"100%"}}></hr>
    </div>
  )
}

export default Batch_starting_date
