import React, { useState, useEffect } from "react";
import "./Admission.css";
import { db } from "../../firebase";
import Navbar from "../Navbar/Navbar";

const Admission = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [course, setCourse] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [fatherFirstName, setFatherFirstName] = useState("");
  const [fatherMiddleName, setFatherMiddleName] = useState("");
  const [fatherLastName, setFatherLastName] = useState("");
  const [fatherMobile, setFatherMobile] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");

  function randomString() {
    var result = "";
    let length = 20;
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const handleSubmit = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      dob === "" ||
      age === "" ||
      course === "" ||
      email === "" ||
      mobile === "" ||
      fatherFirstName === "" ||
      fatherLastName === "" ||
      fatherOccupation === ""
    ) {
      alert(
        "Kindly fill all the necessary details such as first name, last name, DOB, age, course selection, email, mobile, fathers's first and last name and occupation."
      );
    } else {
      const id = randomString();

      db.collection("admissions_db").doc(id).set({
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        dob: dob,
        age: age,
        course: course,
        email: email,
        mobile: mobile,
        fatherFirstName: fatherFirstName,
        fatherMiddleName: fatherMiddleName,
        fatherLastName: fatherLastName,
        fatherMobile: fatherMobile,
        fatherOccupation: fatherOccupation,
      });

      setFirstName("");
      setMiddleName("");
      setLastName("");
      setDob("");
      setAge("");
      setCourse("");
      setEmail("");
      setMobile("");
      setFatherFirstName("");
      setFatherMiddleName("");
      setFatherLastName("");
      setFatherMobile("");
      setFatherOccupation("");
      alert("Submitted Successfully");
    }
  };

  return (
    <>
    <Navbar/>
    <div className="Adm_container">
      <div className="Adm_heading">
        <span>KINEMATICS CLASSES</span>
      </div>
      <div className="Adm_sub_heading">
        <span>ज्ञानम् परम् ध्येयम्</span>
      </div>
      <div className="Adm_form_container">
        <div className="Adm_form">
          <div className="Adm_form_img_container">
            <img alt="" src="./admission_img.png" />
          </div>
          <div className="Adm_form_form">
            <div className="Adm_form_form_heading">
              <span>Student Admission Form</span>
              <hr />
            </div>
            <div className="Adm_form_form_Ist">
              <div className="Adm_form_form_Ist_name">
                <span className="label">*Student Name</span>
              </div>
              <div className="Adm_form_form_Ist_input">
                <input
                  type="text"
                  placeholder="*First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  required={true}
                />
                <input
                  className="secondinput"
                  type="text"
                  placeholder="Middle Name"
                  onChange={(e) => setMiddleName(e.target.value)}
                  value={middleName}
                />
                <input
                  className="thirdinput"
                  type="text"
                  placeholder="*Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required={true}
                />
              </div>
            </div>
            <div className="Adm_form_form_IInd">
              <div className="Adm_form_form_IInd_DOB">
                <span className="label">*Date of Birth</span>
                <input
                  type="date"
                  onChange={(e) => setDob(e.target.value)}
                  value={dob}
                  required={true}
                />
              </div>
              <div className="Adm_form_form_IInd_Age">
                <span className="label">*Age</span>
                <input
                  type="number"
                  placeholder="In years"
                  onChange={(e) => setAge(e.target.value)}
                  value={age}
                  required={true}
                />
              </div>
              <div className="Adm_form_form_IInd_Course">
                <label htmlFor="course" className="label">
                  *Course
                </label>
                <select
                  name="course"
                  id="course"
                  onChange={(e) => setCourse(e.target.value)}
                  value={course}
                  required={true}
                >
                  <option value="" style={{ display: "none" }}>
                    Select
                  </option>
                  <option value="9th">Class 9</option>
                  <option value="10th">Class 10</option>
                  <option value="11Jee">Class 11 (JEE)</option>
                  <option value="11Neet">Class 11 (NEET)</option>
                  <option value="12Jee">Class 12 (JEE)</option>
                  <option value="12Neet">Class 12 (NEET)</option>
                  <option value="12PassJee">Class 12 pass (JEE)</option>
                  <option value="12PassNeet">Class 12 pass (NEET)</option>
                </select>
              </div>
            </div>
            <div className="Adm_form_form_IIIrd">
              <div className="Adm_form_form_IIIrd_email_Id">
                <span className="label">*Student email Id</span>
                <input
                  type="email"
                  placeholder="*mail@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required={true}
                />
              </div>
              <div className="Adm_form_form_IIIrd_no">
                <span className="label">*Student Contact No.</span>
                <div className="Adm_form_form_contactNo">
                  <img alt="" src="./india_icon.png" />
                  <span className="ind">IND</span>
                  <span className="+91">+91</span>
                  <input
                    type="number"
                    placeholder="99999 99999"
                    min="1000000000"
                    max="9999999999"
                    onChange={(e) => setMobile(e.target.value)}
                    value={mobile}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="Adm_form_form_Ist">
              <div className="Adm_form_form_Ist_name">
                <span className="label">*Father's Name</span>
              </div>
              <div className="Adm_form_form_Ist_input">
                <input
                  type="text"
                  placeholder="*First Name"
                  onChange={(e) => setFatherFirstName(e.target.value)}
                  value={fatherFirstName}
                  required={true}
                />
                <input
                  className="secondinput"
                  type="text"
                  placeholder="Middle Name"
                  onChange={(e) => setFatherMiddleName(e.target.value)}
                  value={fatherMiddleName}
                />
                <input
                  className="thirdinput"
                  type="text"
                  placeholder="*Last Name"
                  onChange={(e) => setFatherLastName(e.target.value)}
                  value={fatherLastName}
                  required={true}
                />
              </div>
            </div>
            <div className="Adm_form_form_IIIrd">
              <div className="Adm_form_form_IIIrd_no">
                <span className="label fcontact">*Father's Contact No.</span>
                <div className="Adm_form_form_contactNo">
                  <img alt="" src="./india_icon.png" />
                  <span className="ind">IND</span>
                  <span className="+91">+91</span>
                  <input
                    type="number"
                    placeholder="99999 99999"
                    min="1000000000"
                    max="9999999999"
                    onChange={(e) => setFatherMobile(e.target.value)}
                    value={fatherMobile}
                    required={true}
                  />
                </div>
              </div>
              <div className="Adm_form_form_Vth_name">
                <span className="label">*Father's Occupation</span>
                <input
                  type="text"
                  onChange={(e) => setFatherOccupation(e.target.value)}
                  value={fatherOccupation}
                  required={true}
                />
              </div>
            </div>
          </div>
          <div className="Adm_form_Bottom">
            <button
              onClick={handleSubmit}
              type="submit"
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Admission;
