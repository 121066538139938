import React from 'react'
import './Upcomming_test.css'

const Upcomming_test = ({
  date,
  description,
  heading,
  syllabus,
  eligibility,
  releasedDate
}) => {
  return (
    <div className='notifications'>
        <div className='notification__'>
            <div className='upload_date'>{releasedDate}</div>
            <hr style={{width:"100%"}}></hr>
            <div className='notification__heading'>{heading}</div>
            <div><span style={{fontWeight:"500"}}>Test date : </span>{date}</div>
            <div><span style={{fontWeight:"500"}}>Eligibility : </span>{eligibility}</div>
            <div><span style={{fontWeight:"500"}}>Test syllabus : </span>{syllabus}</div>
            <div>{description}</div>
        </div>
        <hr style={{width:"100%"}}></hr>
    </div>
  )
}

export default Upcomming_test
