import './App.css';
import { Routes, Route, Navigate, Link } from "react-router-dom";
import Home from './Components/Home/Home';
import Login from './Components/LoginPage';
import Admission from './Components/AdmissionPage/Admission';
import Aboutpage from './Components/AboutPage/Aboutpage';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy'
import Contact from './Components/LandingPage/Institute Explore/Contact/Contact';
import Results from './Components/Results/Results';
import { auth } from './firebase'
import { useEffect, useState } from 'react';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import UpdatePassword from './Components/UpdatePassword/UpdatePassword';
import { SyncLoader } from 'react-spinners';
import Notification from './Components/Notification/Notification';

// test@test.com
// Kinematics#123


function App() {
  const [user, setUser] = useState(undefined);

  const setMetaData = () => {
    auth.onAuthStateChanged(res => {
      setUser(res);
      // null -> logged out
      // some value -> logged in
      // undefined -> we need to show the loader as 
      // we don't know anything about the user till now
      console.log(user)
    })
  }

  useEffect(() => {

    setMetaData();

    return () => {

    }
  }, [])

  console.log(user);

  if (user === undefined) {
    return (
      <div style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <SyncLoader color="#FF543E" />
      </div> /// replace this code with your custom loader
    )
  }

  if (user) {
    return (
      <>
        <Routes>
          <Route
            path="/" element={<Home />}
          />
          {/* <Route
            path="/login" element={<Login />}
          /> */}
          <Route
            path="/admission" element={<Admission />}
          />
          <Route
            path="/about" element={<Aboutpage />}
          />
          <Route
            path="/privacy-policy" element={<PrivacyPolicy />}
          />
          <Route
            path="/results" element={<Results />}
          />
          <Route
            path="/update-password" element={<UpdatePassword />}
          />
          <Route
            path='*'
            element={<Navigate to={'/'} />}
          /// will take it user to the home page 
          /// if he/she enters any kind of non existant url
          />
          <Route path='/notifications' element={<Notification/>} />
        </Routes>
      </>
    );
  } else {
    return (
      <>
        <Routes>
          <Route
            path="/" element={<Home />}
          />
          <Route
            path="/login" element={<Login />}
          />
          <Route
            path="/admission" element={<Admission />}
          />
          <Route
            path="/about" element={<Aboutpage />}
        />
        <Route
            path="/privacy-policy" element={<PrivacyPolicy />}
          />
          <Route
            path="/forgot-password" element={<ForgotPassword />}
          />
          <Route
            path='*'
            element={<Navigate to={'/'} />}
          />
          <Route path='/notifications' element={<Notification/>} />
        </Routes>
      </>
    );
  }

}

export default App;
