import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Navbar.css";
import Sidebar from "./Sidebar/Sidebar";
import { auth } from "../../firebase";
import { SyncLoader } from "react-spinners";

const Navbar = () => {
  const [isSidebar, setIsSidebar] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(undefined);
  const [loader, setLoader] = useState(false);

  const setMetaData = () => {
    auth.onAuthStateChanged((res) => {
      setUser(res);
      // null -> logged out
      // some value -> logged in
      // undefined -> we need to show the loader as
      // we don't know anything about the user till now
    });
  };

  useEffect(() => {
    setMetaData();
    return () => {};
  }, []);

  const handleClick = () => {
    navigate("/login");
  };

  const menubar = () => {
    console.log("open");
    setIsSidebar(true);
  };
  const handleback = () => {
    setIsSidebar(false);
  };

  const homepage = () => {
    navigate("/");
  };

  const handleLogout = () => {
    console.log("Logging out");
    auth.signOut();
    alert("Logged Out Successfully")
    navigate("/");
  };

  // console.log(isSidebar);

  return (
    <>
      <div className="navbar__container">
        <div className="nav__bgtext">KINEMATICS CLASSES</div>
        <div className="nav__top">
          <div className="nav__top__logo">
            <img src="/logo.png" alt="Logo" onClick={() => homepage()} />
          </div>
          <div className="responsive_nav_text">KINEMATICS CLASSES</div>
          <div className="nav__top__options">
            <Link to="/">Home</Link>
            <a href="/#courses">Courses</a>
            {user ? <Link to="/results">Results</Link> : <Link to="/admission">Admission</Link> }
            <Link to="/notifications">Schedule</Link>
            <Link to="/about">About</Link>
            <a href="/#contact">Contact</a>
          </div>
          <div className="nav__top__login">
            {!user ? (
              <button onClick={() => handleClick()}>{loader? <SyncLoader color="#F4F4FF" size={10} /> : 'Login'}</button>
            ) : (
              <button onClick={() => handleLogout()}>{loader? <SyncLoader color="#F4F4FF" size={10} /> : 'Log Out'}</button>
            )}
          </div>
          <div className="nav__top__menu__bar">
            <img src="./nav_menu.png" alt="" onClick={() => menubar()} />
          </div>
        </div>
        <div className="nav__bottom">
          <div className="nav__botton__text__margin"></div>
          <div className="nav__bottom__text">KINEMATICS CLASSES</div>
          <div className="nav__bottom__socials">
            <a href="https://www.youtube.com/@KINEMATICSCLASSES24">
              <img src="/youtube.png" alt="icon" />
            </a>
            <a href="https://www.facebook.com/kinematicsclasses.page">
              <img src="/facebook.png" alt="icon" />
            </a>
            <a
              href="https://www.instagram.com/kinematicsclassespvtltd/"
              target="_blank"
            >
              <img src="/instagram.png" alt="icon" />
            </a>
            <a href="https://wa.me/8527915213" target="_blank" rel="noreferrer">
              <img src="/whatsapp.png" alt="icon" />
            </a>
          </div>
        </div>
      </div>
      <Sidebar value={isSidebar} onback={() => handleback()} />
    </>
  );
};

export default Navbar;
