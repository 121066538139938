import React from 'react'
import "./LandingPage.css"
import Institute_Explore from "./Institute Explore/Institute_Explore.js"
import UpperPage from './UpperPage/UpperPage.js'

const LandingPage = () => {
  return (
    <>
    <UpperPage/>
    <Institute_Explore/>
    </>
  )
}

export default LandingPage
