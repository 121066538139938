import React from "react";
import "./Aboutpage.css";
import Navbar from "../Navbar/Navbar.js";

const Aboutpage = () => {
  return (
    <>
      <Navbar />
      <div className="about__container">
        <div>
          <div className="about__page__headings">About Kinematics Classes - Shaping Futures, Igniting Minds</div>
          <div className="about__page__description">
            Welcome to Kinematics Classes, Ghaziabad's premier destination for
            quality education and holistic development. Established with a
            passion for nurturing young minds, Kinematics Classes is more than
            just an institute; it's a community dedicated to excellence in
            science education.
          </div>
        </div>
        <div>
          <div className="about__page__headings">Our Mission:</div>
          <div className="about__page__description">At Kinematics Classes, our mission is to empower students with knowledge, foster critical thinking, and instill a love for learning. We believe in cultivating not just academic success but also in shaping well-rounded individuals equipped to face the challenges of the future.</div>
        </div>
        <div>
          <div className="about__page__headings">Our Vision:</div>
          <div className="about__page__description">Our vision is to be a beacon of educational excellence, inspiring students to explore their potential, develop a scientific temperament, and contribute meaningfully to society. We strive to create an environment where curiosity is celebrated, and the pursuit of knowledge becomes a lifelong journey.</div>
        </div>
        <div>
          <div className="about__page__headings">Our Courses:</div>
          <div className="about__page__description">9th foundation <span>(course name: उदय)</span> : Here we create a strong foundation for early Birds. It brings deep knowledge and interest towards science And maths and a student does really well in various competitive exams..</div>
          <div className="about__page__description">10th foundation <span>(course name:  उद्भव)</span> : Here we offer indepth knowledge of subject sighting the 10th BOARD exam and work of question solving abilities of blossoming students.</div>
          <div className="about__page__description">11th & 12th IIT and NEET <span>(course name: प्रताप)</span> : A two intense program focussing on conceptual and practical knowledge of PCMB. </div>
          <div className="about__page__description">12th pass: <span>(वेदांश)</span></div>
        </div>
      </div>
    </>
  );
};

export default Aboutpage;
