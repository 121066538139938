import React, { useState } from "react";
import "./ForgotPassword.css";
import { auth } from "../../firebase";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";

// pg99285@gmail.com

function ForgotPassword() {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleChange = (e, target) => {
    setFormData((prev) => {
      return {
        ...prev,
        [target]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    const { email } = formData;
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setDisableSubmit(false);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/user-not-found":
            setDisableSubmit(false);
            break;
          default:
            setDisableSubmit(false);
        }
      });

      setFormData({
        email: ""
      })
  };

  return (
    <>
      <Navbar />

      <div className="mainContainer">
        <div className="loginheading Mobileview">KINEMATICS CLASSES</div>
        <div className="notification Mobileview">
          *Results are out. Login here check your score.
        </div>
        <div></div>
        <div className="container">
          <div className="loginContainer">
            <div className="imgContainer">
              <img
                className="Webview"
                src="./a-small-student-studying-in-night.jpg"
                alt=""
              ></img>
              <img className="Mobileview" src="./newMobile.jpg" alt=""></img>
            </div>
            <div className="formContainer">
              <div className="loginheading Webview">KINEMATICS CLASSES</div>
              <div className="notification Webview">
                *Enter your email to get a reset password link on your email id
              </div>
              <div className="subHeading">Forgot Password</div>
              <form onSubmit={handleSubmit}>
                <div className="form">
                  <input
                    type="email"
                    onChange={(e) => handleChange(e, "email")}
                    placeholder="Student Email"
                  />
                  {disableSubmit ? 
                  <>
                  <button className="loginButton" style={{backgroundColor: 'gray'}} disabled={disableSubmit}>
                  Sent
                </button>
                <p className="forgetButton">Check your mail to reset your password</p>
                </>
                : 
                <button className="loginButton" disabled={disableSubmit}>
                    Submit
                  </button>
                  }
                </div>
              </form>
              <div className="bottom">
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
