import React, { useState, useEffect } from "react";
import "./Results.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Scrollbar, A11y } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { db } from "../../../../firebase";

const Results2 = () => {
  const [database, setDatabase] = useState([{}]);
  useEffect(() => {
    const fetch = async () => {
      db.collection('results_db').onSnapshot(snapshot => {
        setDatabase(snapshot.docs.map((data) => {
          return {
              url: data.data().photoUrl,
              course: data.data().course,
              name: data.data().name,
              score: data.data().score
          }
        }))
      })
    }

    fetch();
  }, [])

  return (
    <div className="results__container">
      <Swiper
        modules={[Autoplay, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={3}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        loop={true}
      > {database.map((data, i) => (
        <SwiperSlide key={i}>
          <img className="slide__image" src={data.url} alt="" />
          <p className="slide__text">{data.name}</p>
          <p className="slide__text">{data.course}</p>
          <p className="slide__text">{data.score}</p>
        </SwiperSlide>
      ))}
      </Swiper>
    </div>
  );
};

export default Results2;