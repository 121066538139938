import React, { useEffect, useState } from "react";
import "./UpperPage.css";
import { db } from "../../../firebase";

// Importing Swiper Files from library
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";



const UpperPage = () => {
  // ज्ञानं परमं ध्येयम् | (Knowledge is our supreme goal)
 
    const [imgUrls, setImgUrls] = useState([]);

    useEffect(() => {
      const fetch = async () => {
        db.collection('topcarousel_db').onSnapshot(snapshot => {
          setImgUrls(snapshot.docs.map((data) => {
            return {
              url: data.data().photoUrl
            }
          }))
        })
      }

      fetch();
    }, [])

    
    return (
    <div className="home">
      <div className="leftContainer">
        <div className="heading">
          <span className="Istheading">ज्ञानम् परम् ध्येयम्</span>
          <span className="IIIrdheading">Knowledge is</span>
          <span className="IIIrdheading">our<span className="headingSpan"> supreme </span></span>
          <span className="IIIrdheading">goal</span>
          <div className="IIIlustration">
            <img
              className="IIIlustrationImg"
              alt=""
              src="/IIIustration_landingpage.png"
            />
          </div>
          <div >
            <a href="#courses" style={{textDecoration: "none"}}>
            <button className="headingBottom">Explore Courses
            <img className="buttonImg" src="/dropdownarrow.png" alt=""/>
            </button>
            </a>
          </div>
          <div className="description">
            <span className="descriptionSpan">
              **Join us to get best coaching
            </span>
            <span className="descriptionSpan">
              guidance with our best mentors.**
            </span>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Swiper
          effect={"coverflow"}
          loop={true}
          slideShadows={true}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 50,
            modifier: 5,
          }}
          autoplay={{
            delay: 1000,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          {imgUrls.map((urls, i) => (
            <SwiperSlide key={i}>
              <img src={urls.url} alt="slide_image" />
            </SwiperSlide>
          ))}

          <div className="slider-controller">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default UpperPage;
