import React from 'react'
import { useNavigate } from "react-router-dom";
import Youtube from '../Institute Explore/Youtube/Youtube.js'
import Contact from "../Institute Explore/Contact/Contact.js";
import "./Courses.css"

const Courses = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/admission");
      }
  return (
    <div style={{backgroundColor: "rgba(244, 244, 244, 1)"}}>
      <div
            className="ins__heading our__directors"
            style={{ color: "#0F3D82" }}
          >
            &gt; &gt; &gt; Courses
          </div>
          <div id="courses" className="courses__container">
            <div className="courses__container__top">
              <div className="courses__container__top__left">
                <p style={{marginBottom: "20px"}}>
                Welcome to our coaching Kinematics Classes, where academic excellence meets comprehensive preparation! Our offerings include a solid Foundation Course tailored for IX<sup>th</sup> and X<sup>th</sup> graders, specialized JEE preparation for XI<sup>th</sup>, XII<sup>th</sup> and XII<sup>th</sup>+1, and NEET-focused programs for those in XI<sup>th</sup>, XII<sup>th</sup>, and XII<sup>th</sup>+1. Unlock your potential with our expert guidance and structured curriculum designed to propel students toward success in competitive exams. Join us on the journey to academic excellence!
                </p>
                <button className="headingBottom registerbtn " onClick={() => handleClick()}>
                  <div className="buttonspan">Register Now</div>
                  <img className="buttonImg" src="/dropdownarrow.png" alt="" />
                </button>
              </div>
              <div className="courses__container__top__right">
                <img src="/courses_side.png" alt="Loading..." />
              </div>
            </div>
            <div id="courses" className="courses__container__bottom">
             <div className='course__img'> 
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/class_9.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/class_10.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/class_11_jee.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/class_11_neet.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/class_12_jee.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/class_12_neet.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/class_13_jee.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/class_13_neet.png"
                    alt=""
                    onClick={() => handleClick()}
                />
              </div>
             <div className='course__img__responsive'>
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/11_jee.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/11_neet.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/12_jee.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/12_neet.png"
                    alt=""
                    onClick={() => handleClick()}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src="/courses/13_jee.png"
                    alt=""
                    onClick={() => handleClick()}
                />
             </div>
            </div>
          </div>
          <div className="ins__bottom_container"><Youtube /></div>
          <div id="contact"><Contact /></div>
    </div>
  )
}
export default Courses