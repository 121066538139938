import React, { useState } from 'react'
import "./style.css"
import Navbar from '../Navbar/Navbar'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { Link } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgot, setForgot] = useState(false);
    const [info, setInfo] = useState('');
    const navigate = useNavigate();
    const authentication = getAuth();
    const [loader, setLoader] = useState(false);

    const handleLogin = () => {
        if (email === '' || password === '') {
            alert("Kindly fill complete details")
        } else {
            setLoader(true);
            auth.signInWithEmailAndPassword(email, password)
                .then((user) => {
                    console.log("signed in successfully");
                    navigate('/results')
                }).catch((err) => {
                    alert(err);
                    setLoader(false);
                })
        }
    }

    const forgotPassword = async () => {
        setForgot(false);
        if (email === '') {
            alert("Please enter your email for which you want to reset the password")
        } else {
            await auth.sendPasswordResetEmail(email)
                .then(() => {
                    setInfo("Password Reset link sent to your email");
                    setEmail('');
                }).catch((err) => {
                    alert(err);
                })
        }
    }

    const logout = () => {
        // console.log(auth.currentUser);
        console.log("Logging out")
        auth.signOut()
        alert("Logged Out")
    }

    return (
        <>
            <Navbar />
            <div className='mainContainer'>
                <div className='loginheading Mobileview'>KINEMATICS CLASSES</div>
                <div className='notification Mobileview'>*Results are out. Login here check your score.</div>
                <div></div>
                <div className='container'>
                    <div className='loginContainer'>
                        <div className='imgContainer'>
                            <img className="Webview" src='./a-small-student-studying-in-night.jpg' alt=''></img>
                            <img className="Mobileview" src='./newMobile.jpg' alt=''></img>
                        </div>
                        <div className='formContainer'>
                            <div className='loginheading Webview'>KINEMATICS CLASSES</div>
                            <div className='notification Webview'>*Results are out. Login here check your score.</div>
                            <div className='subHeading'>Student Credentials</div>
                            <div className='form'>
                                <input type='email' placeholder='*Student Id' value={email} onChange={e => setEmail(e.target.value)} />
                                <input type='password' placeholder='*Password' value={password} onChange={e => setPassword(e.target.value)} />
                            </div>
                            <div className='bottom'>
                                <Link className='forgetButton' to='/forgot-password'>Forgot Password</Link>
                                <button className='loginButton' onClick={handleLogin}>{loader? <SyncLoader color="#F4F4FF" size={10} /> : 'Login'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
