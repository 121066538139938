import React, { useState, useEffect } from 'react'
import "./Notification.css"
import { db } from "../../firebase";
import Navbar from '../Navbar/Navbar'
import Upcomming_test from './Upcomming test/Upcomming_test'
import Batch_starting_date from './Batch_starting_date/Batch_starting_date'

const Notification = () => {

  const [testsched, setTestSched] = useState([{}]);
  const [batch, setBatch] = useState([{}]);

    useEffect(() => {
      const fetch = async () => {
        try {
          const snapshot = await db.collection("testSchedule_db").get();
          setTestSched(
            snapshot.docs.map((data) => ({
              date: data.data().date,
              description: data.data().description,
              heading: data.data().heading,
              syllabus: data.data().syllabus,
              eligibility: data.data().eligibility,
              releasedDate: data.data().releasedDate
            }))
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
      fetch();
    }, []);

  useEffect(() => {
    const fetch = async () => {
      db.collection("classSchedule_db").onSnapshot((snapshot) => {
        setBatch(
          snapshot.docs.map((data) => {
            return {
              releasedDate: data.data().releasedDate,
              date9: data.data().date9,
              date10: data.data().date10,
              date11Jee: data.data().date11Jee,
              date11Neet: data.data().date11Neet,
              date12Jee: data.data().date12Jee,
              date12Neet: data.data().date12Neet,
              date13Jee: data.data().date13Jee,
              date13Neet: data.data().date13Neet,
              description: data.data().description,
              heading: data.data().heading
          }}));
        });
    };

    fetch();
  }, []);

  const [tab, setTab] = useState("test");

  const handleTest = (e) => {
    e.preventDefault();
    setTab('test');
  }
  const handleBatch = (e) => {
    e.preventDefault();
    setTab('batch');
  }

  return (
    <>
        <Navbar/>
        <div className='notifications__main__container'>
            <div className='notifications__container'>
                <div className='notification__select'>
                    <div className='notifications__options' style={{backgroundColor: tab === 'test' ? "#F4F4FF" : "#ffff"}} onClick={(e) => handleTest(e)}>Upcoming Test</div>
                    <div className='notifications__options' style={{backgroundColor: tab === 'batch' ? "#F4F4FF" : "#ffff"}} onClick={(e) => handleBatch(e)}>Batch Starting date</div>
                </div>
                <hr></hr>
                <div className='notification__map__container'>
                  {tab === 'test' ? testsched.map((data) => {
                    return <Upcomming_test date={data.date} 
                    description={data.description} 
                    heading={data.heading}
                    syllabus={data.syllabus}
                    eligibility={data.eligibility}
                    releasedDate={data.releasedDate}
                    />
                  }) :batch.map((data) => {
                    return <Batch_starting_date
                    releasedDate={data.releasedDate}
                    description={data.description} 
                    heading={data.heading}
                    date9={data.date9}
                    date10={data.date10}
                    date11Jee={data.date11Jee}
                    date11Neet={data.date11Neet}
                    date12Jee={data.date12Jee}
                    date12Neet={data.date12Neet}
                    date13Jee={data.date13Jee}
                    date13Neet={data.date13Neet}
                    />
                  })}
                </div>
            </div>
        </div>
    </>
  )
}

export default Notification
